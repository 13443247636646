<template>
  <div class="container">
    <div class="border_wrapper">
      <div class="left_border">
        <div class="actual_border"></div>
        <div class="border_cover" :style="{ transform: `rotate(${left}deg)` }">
          <div class="cover_half"></div>
        </div>
        <div class="permanent_bottom"></div>
      </div>
      <div class="right_border">
        <div class="actual_border"></div>
        <div class="border_cover" :style="{ transform: `rotate(${right}deg)` }">
          <div class="cover_half"></div>
        </div>
        <div class="permanent_bottom"></div>
      </div>
      <div class="center"></div>
    </div>
    <div class="left-indicator">
      <span>E1</span>
      <span>{{leftActual}}</span>
      <!-- <span>{{left}}</span> -->
    </div>
    <div class="right-indicator">
      <span>E2</span>
      <span>{{rightActual}}</span>
      <!-- <span>{{right}}</span> -->
    </div>
    <!-- <div class="value">{{ val }}&deg;</div> -->
    <div class="title">
      <div>{{ title }}</div>
      <div class="sub" v-if="subtitle">{{ subtitle }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, watch } from 'vue';
const props = defineProps({ val_left: Number, val_right: Number, title: String, subtitle: String});
const { val_left,val_right } = toRefs(props);

let leftActual = ref(0);
let rightActual = ref(0);

let left = ref(-30);
let right = ref(137);
let gleft = ref(0);
let gright = ref(0);
let val = ref(0);

watch(props, () => {
  if (props.title === 'rpm') { 
    left.value = Math.ceil((props.val_left / 1000) * 95 - 30);
    right.value = Math.ceil(137 - (props.val_right / 1000) * 95);
    leftActual.value = props.val_left;
    rightActual.value = props.val_right;
  } else if (props.title === 'oil') { 
    left.value = Math.ceil((props.val_left / 100) * 95 - 30);
    right.value = Math.ceil(137 - (props.val_right / 100) * 95);
    leftActual.value = props.val_left;
    rightActual.value = props.val_right;
  } else if (props.title === 'temp') { 
    left.value = Math.ceil((props.val_left / 200) * 95 - 30);
    right.value = Math.ceil(137 - (props.val_right / 200) * 95);
    leftActual.value = props.val_left;
    rightActual.value = props.val_right;
  }
    
  gleft.value = (48.5-0.1583*props.val_left).toFixed(0);
  gright.value = (48.5-0.1583*props.val_right).toFixed(0);
    
  const v = props.val_right - props.val_left;
  const r = 1314;
  const a = 0 - (Math.asin(v/r) * 180 / Math.PI);
  val.value = a.toFixed(1);
});

</script>

<style lang="scss" scoped>
.container {
  position: relative;
  contain: layout;
  border-radius: 50%;
  overflow: hidden;
  background-color: #787878;
}
.back-mask {
  position:absolute;
  left: 0;
  bottom: 0;
  height: 31%;
  width: 100%;
  z-index:2;
}
.value {
  position: absolute;
  text-align: center;
  left: 0%;
  top: 70%;
  height:100%;
  width:100%;
  z-index: 5;
  color: white;
  font-size: 80%;
}
.right-indicator,.left-indicator {
  position: absolute;
  text-align: center;
  top: 50%;
  height: 30%;
  width: 30%;
  z-index: 5;
  color: white;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  span{
    line-height: 120%;
  }
}
.left-indicator {
  left: 15%;
}
.right-indicator {
  right: 15%;
}
.title {
  position: absolute;
  text-align: center;
  left: 0%;
  top: calc(45% - 1.2rem);
  width: 100%;
  z-index: 5;
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sub{
    font-weight: 400;
    font-size: 50%;
  }
}
.border_wrapper{
  position: absolute;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  top: 5px;
  left: 5px;
  border-radius: 50%;
  overflow: hidden;
  .center{
    position: absolute;
    top: 5px;
    left: 5px;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    border-radius: 50%;
    background: #787878;
  }
  .left_border,.right_border{
    position: absolute;
    height: 100%;
    width: 100%;
    .actual_border{
      position: absolute;
      bottom: 0px;
      width: 20%;
      height: 100%;
      background: #fff;
      border-radius: 5px;
    }
    .border_cover{
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all .1s ease-in-out;
      .cover_half{
        position: absolute;
        width: 50%;
        height: 64%;
        background-color: #787878;
        left: 0px;
      }
    }
    .permanent_bottom{
      position: absolute;
      width: 20%;
      height: 10%;
    }
  }
  .left_border{
    transform: rotate(-20deg);
    .actual_border{
      left: 0px;
    }
    .border_cover{
      transform: rotate(65deg);
    }
    .permanent_bottom{
      background-color: #787878;
      bottom: 5px;
      left: 10px;
      transform: rotate(135deg);
    }
  }
  .right_border{
    transform: rotate(20deg);
    .actual_border{
      right: 0px;
    }
    .border_cover{
      transform: rotate(42deg);
    }
    .permanent_bottom{
      background-color: #787878;
      bottom: 2px;
      right: 7px;
      transform: rotate(42deg);
    }
  }
}
</style>